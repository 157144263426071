import { render, staticRenderFns } from "./HomeBoxes.vue?vue&type=template&id=742b3778&scoped=true"
import script from "./HomeBoxes.vue?vue&type=script&lang=js"
export * from "./HomeBoxes.vue?vue&type=script&lang=js"
import style0 from "./HomeBoxes.vue?vue&type=style&index=0&id=742b3778&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "742b3778",
  null
  
)

export default component.exports