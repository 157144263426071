var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flexbox-container hide-sm-and-down"},[_c('div',{staticClass:"box box-1",on:{"click":function($event){_vm.$router.push('/tags/non-probationable-list').catch(err => {
        _vm.$vuetify.goTo(0);
      })}}},[_c('div',{staticClass:"text-center mb-5"},[_c('v-icon',{staticStyle:{"color":"#fff"},attrs:{"size":"80px"}},[_vm._v("insert_chart")])],1),_c('h2',{staticClass:"heavy text-center"},[_vm._v("Inventory of Non-Probationable Offenses")]),_c('p',{staticClass:"pt-4"},[_vm._v(" Offenses to which mandatory minimum prison sentences apply. ")])]),_c('div',{staticClass:"box box-2",on:{"click":function($event){_vm.$router.push('/tags/prison-pies').catch(err => {
        _vm.$vuetify.goTo(0);
      })}}},[_c('div',{staticClass:"text-center mb-5"},[_c('v-icon',{staticStyle:{"color":"#fff"},attrs:{"size":"80px"}},[_vm._v("pie_chart")])],1),_c('h2',{staticClass:"heavy text-center"},[_vm._v("Pies and Average Profiles")]),_c('p',{staticClass:"pt-4"},[_vm._v(" Prison population pie charts and descriptive profiles of people convicted in Illinois. ")])]),_c('div',{staticClass:"box box-3",on:{"click":function($event){_vm.$router.push('/tags/fiscal-impact').catch(err => {
        _vm.$vuetify.goTo(0);
      })}}},[_c('div',{staticClass:"text-center mb-5"},[_c('v-icon',{staticStyle:{"color":"#fff"},attrs:{"size":"80px"}},[_vm._v("monetization_on")])],1),_c('h2',{staticClass:"heavy text-center"},[_vm._v("Impact Analyses")]),_c('p',{staticClass:"pt-4"},[_vm._v(" Estimates of the benefits and costs of proposed sentencing legislation and policy changes. ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }